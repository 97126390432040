import { BigNumber } from "@ethersproject/bignumber";
import { createReducer } from "@reduxjs/toolkit";
import { NFTExport } from "../../models/exports";
import {
  addNft,
  clearNft,
  removeNft,
  forwardStep,
  backwardStep,
  setStep,
  setDetails,
  resetAlbumSetup,
} from "./actions";

export enum CreateAlbumStep {
  NO_PROFILE,
  YOUR_ALBUMS,
  ALBUM_DETAILS,
  SELECT_NFTS,
  APPROVE_NFTS,
  CONFIRM,
  COMPLETE,
}

//Fields taken from CreateAlbumParams from factory.ts in szns-sc
export interface CreateAlbumDetails {
  name: string;
  description: string;
  pic: string; //Album profile pic
  tokenName: string;
  totalSupply: string; //Total album tokens to mint
  amountSold: string; //Amount to sell during initial sale
  tokensPerWei: string; //hex representation of bignumber
  saleStart: number; //seconds unix epoch time
  saleEnd: number; //seconds unix epoch time
  createdHash?: string | undefined;
}

interface CreateAlbumState {
  createStep: CreateAlbumStep;
  details: CreateAlbumDetails;
  selectedNfts: NFTExport[];
}

const initialState: CreateAlbumState = {
  createStep: CreateAlbumStep.NO_PROFILE,
  details: {
    name: "",
    description: "",
    pic: undefined,
    tokenName: "",
    tokensPerWei: "",
    totalSupply: "",
    amountSold: "",
    saleStart: -1,
    saleEnd: -1,
    createdHash: undefined,
  },
  selectedNfts: [],
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(addNft, (state, action) => {
      if (action.payload) {
        state.selectedNfts.push(action.payload);
      }
    })
    .addCase(removeNft, (state, action) => {
      state.selectedNfts = state.selectedNfts.filter((nft) => {
        return nft.id !== action.payload.id
          ? true
          : nft.type.contract !== action.payload.type.contract
          ? true
          : false;
      });
    })
    .addCase(clearNft, (state) => {
      state.selectedNfts = [];
    })
    .addCase(forwardStep, (state) => {
      state.createStep = state.createStep + 1;
    })
    .addCase(backwardStep, (state) => {
      state.createStep = state.createStep - 1;
    })
    .addCase(setStep, (state, action) => {
      state.createStep = action.payload;
    })
    .addCase(setDetails, (state, action) => {
      state.details = action.payload;
    })
    .addCase(resetAlbumSetup, (state) => {
      return initialState;
    });
});
