import { createReducer } from "@reduxjs/toolkit";
import { ProfileExport } from "../../models/exports";
import { updateProfile, updateShowFirstLogin } from "./actions";

interface ProfileState extends ProfileExport {
  showFirstLogin: boolean;
}

export const initialState: ProfileState = {
  userID: null,
  address: null,
  name: null,
  email: null,
  username: null,
  socialMedia: [],
  fundsCurated: [],
  fundsParticpating: [],
  createdAt: null,
  showFirstLogin: false,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateProfile, (state, action) => {
      if (action.payload) {
        const profile = action.payload;

        state.userID = profile.userID;
        state.address = profile.address;
        state.name = profile.name;
        state.email = profile.email;
        state.username = profile.username;
        state.socialMedia = profile.socialMedia;
        state.fundsCurated = profile.fundsCurated;
        state.fundsParticpating = profile.fundsParticpating;
        state.createdAt = profile.createdAt;
      } else {
        return initialState; //Otherwise just clear if profile is null
      }
    })
    .addCase(updateShowFirstLogin, (state, action) => {
      state.showFirstLogin = action.payload;
    })
);
