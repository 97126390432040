//Taken from uniswap implementation
//https://github.com/Uniswap/uniswap-interface/tree/main/src

//This will be the entry point for all our redux state reducers
import { configureStore } from "@reduxjs/toolkit";

// import test from "./test/reducer";
import profile from "./profile/reducer";
import createAlbum from "./createAlbum/reducer";
import editProfile from "./editProfile/reducer";
import signup from "./signup/reducer";
import addNft from "./proposal/addNft/reducer";
import proposal from "./proposal/reducer";
import bounty from "./bounty/reducer";
import transactions from "./transactions/reducer";
import application from "./application/reducer";
import trashdao from "../trashdao/state/reducer";

const store = configureStore({
  reducer: {
    // test, //each reducer is it's own folder. we can split each reducer by feature/function
    profile,
    createAlbum,
    editProfile,
    signup,
    addNft,
    bounty,
    proposal,
    transactions,
    application,
    trashdao,
  },
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
