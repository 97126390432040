import { NFTExport } from "models/exports";
import { StandardProposal } from "../standard";

export class ListNFTProposal implements ListNFTProposal {
  /**
   * Create initial blank list proposal object
   * @returns
   */
  static initialize(): ListNFTProposal {
    return {
      title: "",
      description: "",
      addtionalInfo: "",
      start: undefined,
      end: undefined,
      walletNFTs: undefined,
      selectedNFT: undefined,
      listPrice: undefined,
      startListDate: undefined,
      endListDate: undefined,
    };
  }
}

export interface ListNFTProposal extends StandardProposal {
  walletNFTs: NFTExport[]; //Cache users wallet NFTs during proposal flow
  selectedNFT: NFTExport; //ERC-721 to list
  listPrice: string; //Make this a string since the wei value can be large
  startListDate: string; // unix time (seconds) when the listing starts
  endListDate: string; //Unix time (seconds) of when the listing ends
}
