// Inspired by https://github.com/Uniswap/interface/blob/781e774ce73eb613e78413365553d328264f3d44/src/state/application/hooks.ts
import { DEFAULT_TXN_DISMISS_MS } from "../../constants";
import { useCallback, useMemo } from "react";

import { AppState } from "../index";
import { addPopup, PopupContent, removePopup } from "./reducer";
import { useDispatch, useSelector } from "react-redux";

// returns a function that allows adding a popup
export function useAddPopup(): (
  content: PopupContent,
  key?: string,
  removeAfterMs?: number
) => void {
  const dispatch = useDispatch();

  return useCallback(
    (content: PopupContent, key?: string, removeAfterMs?: number) => {
      dispatch(
        addPopup({
          content,
          key,
          removeAfterMs: removeAfterMs ?? DEFAULT_TXN_DISMISS_MS,
        })
      );
    },
    [dispatch]
  );
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
  const dispatch = useDispatch();
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }));
    },
    [dispatch]
  );
}

// get the list of active popups
export function useActivePopups(): AppState["application"]["popupList"] {
  const list = useSelector<AppState, AppState["application"]["popupList"]>(
    (state: AppState) => state.application.popupList
  );
  return useMemo(() => list.filter((item) => item.show), [list]);
}
