import { StandardProposal } from "./standard";
import { NFTExport } from "models/exports";

export interface AddNftProposal extends StandardProposal {
  price: number; // Album tokens requested for NFT
  selectedNfts: NFTExport[];
}

export class AddNftProposal implements AddNftProposal {
  /**
   * Creates a initial blank New Proposal object
   * @returns
   */
  static initialize(): AddNftProposal {
    return {
      title: "", // we must initialize the StandardProposal fields too
      description: "",
      addtionalInfo: "",
      start: undefined,
      end: undefined,
      price: 0,
      selectedNfts: [],
    };
  }
}
