import { createAction } from "@reduxjs/toolkit";
import { ProfileExport } from "../../models/exports";

export const updateProfile = createAction<ProfileExport>(
  "profile/updateProfile"
);
export const updateName = createAction<{ name: string }>("profile/updateName");

export const updateShowFirstLogin = createAction<boolean>(
  "profile/updateShowFirstLogin"
);
