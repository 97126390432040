import { createAction } from "@reduxjs/toolkit";
import { ProfileExport, SocialMediaLink } from "../../models/exports";

export const updateEditProfile = createAction<ProfileExport>(
  "editProfile/updateEditProfile"
);
export const updateEditName = createAction<string>(
  "editProfile/updateEditName"
);
export const updateEditUsername = createAction<string>(
  "editProfile/updateEditUsername"
);
export const updateEditSocialMedia = createAction<SocialMediaLink>(
  "editProfile/updateEditSocialMedia"
);
export const removeEditSocialMedia = createAction<string>(
  "editProfile/removeEditSocialMedia"
);
