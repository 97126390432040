import { createAction } from "@reduxjs/toolkit";
import { OrderSelection } from "models/bounty";

export const clearSelectedNFTs = createAction(
  "trashdao/bounty/clearSelectedNFTs"
);
export const nftSelected = createAction<OrderSelection>(
  "trashdao/bounty/nftSelected"
);
export const nftDeselected = createAction<OrderSelection>(
  "trashdao/bounty/nftDeselected"
);
export const initalizeCollectionStore = createAction<any>(
  "trashdao/bounty/initalizeCollectionStore"
);
export const initializeAlbumDetails = createAction<{
  albumName: string;
  albumToken: string;
}>("bounty/initializeAlbumDetails");
export const saveOrdersForCollections = createAction<any>(
  "trashdao/bounty/saveOrdersForCollections"
);
export const saveValidatorsForOrders = createAction<any>(
  "trashdao/bounty/saveValidatorsForOrders"
);
export const selectAll = createAction<any>("trashdao/bounty/selectAll");
export const resetBounties = createAction("trashdao/bounty/resetBounties");
export const loadCollections = createAction<any>(
  "trashdao/bounty/loadCollections"
);
export const setAllContractsApproved = createAction<boolean>(
  "trashdao/bounty/setAllContractsApproved"
);
