import { createAction } from "@reduxjs/toolkit";
import { FullAlbumDetails } from "../../models/exports";
import { StandardProposal } from "../../models/standardProposals/standard";
import { ProposalType } from "../../constants/proposals";

export const setProposalType = createAction<ProposalType>(
  "proposal/setProposalType"
);
export const setAlbumDetails = createAction<FullAlbumDetails>(
  "proposal/setAlbumDetails"
);
export const updateProposal = createAction<StandardProposal>(
  "proposal/updateProposal"
);
export const updateTitle = createAction<string>("proposal/updateTitle");
export const updateDescription = createAction<string>(
  "proposal/updateDescription"
);
export const updateProposalStart = createAction<number>(
  "proposal/updateProposalStart"
);
export const updateProposalEnd = createAction<number>(
  "proposal/updateProposalEnd"
);
export const updateProposalAddtlInfo = createAction<string>(
  "proposal/updateProposalAddtlInfo"
);
export const clearProposal = createAction<string>("proposal/clearProposal");
