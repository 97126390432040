//Get the current build, defaults to development
const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";

//Web3 stuff
export const CHAIN_ID = parseInt(process.env.CHAIN_ID) || 4;
export const CHAIN_NAME = process.env.CHAIN_NAME || "rinkeby";
export const ETHERSCAN_URL =
  `${process.env.ETHERSCAN_URL}` || "https://rinkeby.etherscan.io";
export const ETHERSCAN_TXN = `${ETHERSCAN_URL}/tx/`;
export const ETHERSCAN_CONTRACT = `${ETHERSCAN_URL}/address/`;
export const INFURA_KEY = `${process.env.INFURA_KEY}`;
export const ETHERSCAN_KEY = `${process.env.ETHERSCAN_KEY}`;
export const WALLETCONNECT_BRIDGE_URL = "https://bridge.walletconnect.org";
export const SUSHISWAP_URL = "https://app.sushi.com";
export const GNOSIS_SAFE_URL = `https://gnosis-safe.io/app/${
  process.env.CHAIN_NAME === "mainnet" ? "eth" : "rin"
}:`;

export const ENS_BASE = `${process.env.ENS_BASE}` || "seasons.eth";

//API
export const API_URL =
  `${process.env.API_URL}` || "https://szns-staging.uc.r.appspot.com/v1/";

//ABIS
import AlbumFactoryJson from "./abi/szns/AlbumFactory.json";
import AlbumManagerJson from "./abi/szns/Album.json";
import AlbumTokenSaleJson from "./abi/szns/TokenSale.json";
import SznsChefJson from "./abi/szns/SZNSChef.json";
import SznsTokenJson from "./abi/szns/SZNSToken.json";
import BountyBoardJson from "./abi/szns/AlbumBountyBoard.json";
import ValidatorCloneFactoryJson from "./abi/szns/BountyBoard/ValidatorCloneFactory.json";
import ensRegistryJson from "./abi/ensRegistry.json";

export const ABI = {
  ALBUM_FACTORY: AlbumFactoryJson.abi,
  ALBUM_MANAGER: AlbumManagerJson.abi,
  ALBUM_TOKEN_SALE: AlbumTokenSaleJson.abi,
  SZNS_CHEF: SznsChefJson.abi,
  SZNS_TOKEN: SznsTokenJson,
  BOUNTY_BOARD: BountyBoardJson.abi,
  VALIDATOR_CLONE_FACTORY: ValidatorCloneFactoryJson.abi,
  ENS_REGISTRY: ensRegistryJson.abi,
};

export const SNAPSHOT_GRAPHQL_API = "https://hub.snapshot.org/graphql";
export const SNAPSHOT_URL = "https://snapshot.org/#/";

//Proposal params; default 1 week per docs
export const BUYOUT_DURATION = parseInt(process.env.BUYOUT_DURATION) || 604800;
export const ADDNFT_DURATION = parseInt(process.env.BUYOUT_DURATION) || 604800;
export const PROPOSAL_DURATION =
  parseInt(process.env.PROPOSAL_DURATION) || 604800;

export const TOKEN_SALE_DURATION =
  parseInt(process.env.TOKEN_SALE_DURATION) || 604800;

export const DEFAULT_TXN_DISMISS_MS = 10000;

export const OPENSEA_API = {
  1: "https://api.opensea.io/api/v1",
  4: "https://rinkeby-api.opensea.io/api/v1",
};

export const NOTIFICATION_INTERVAL =
  process.env.NOTIFICATION_INTERVAL || 1000 * 10;
export const ALBUM_TOKEN = {
  ROLES: {
    DEFAULT_ADMIN:
      "0x0000000000000000000000000000000000000000000000000000000000000000",
    MINTER_ROLE:
      "0x9f2df0fed2c77648de5860a4cc508cd0818c85b8b8a1ab4ceeef8d981c8956a6",
    PAUSER_ROLE:
      "0x65d7a28e3265b37a6474929f336521b332c1681b933f6cb9f3376673440d862a",
  },
};
