import { createReducer } from "@reduxjs/toolkit";
import { NFTExport } from "../../../models/exports";
import { addNft, removeNft, clearNft } from "./actions";

export const initialState = [] as NFTExport[];

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addNft, (state, action) => {
      if (action.payload) {
        state.push(action.payload);
      }
    })
    .addCase(removeNft, (state, action) => {
      return state.filter((nft) =>
        nft.id !== action.payload.id
          ? true
          : nft.type.contract !== action.payload.type.contract
          ? true
          : false
      );
    })
    .addCase(clearNft, () => {
      return initialState;
    })
);
