import { createAction } from "@reduxjs/toolkit";
import { SocialMediaLink } from "../../models/social_media_link";
import { SignupStep } from "./reducer";

export const forwardStep = createAction("signup/forwardStep");
export const backwardStep = createAction("signup/backwardStep");
export const setStep = createAction<SignupStep>("signup/setStep");
export const updateInfo = createAction<{
  name: string;
  username: string;
  account: string;
}>("signup/updateDetails");
export const updateProfilePic = createAction<string>("signup/updateProfilePic");
export const updateBanner = createAction<string>("signup/updateBanner");
export const updateSocialMedia = createAction<{
  value: string;
  type: "twitter" | "nifty gateway" | "youtube" | "instagram" | "website";
}>("signup/updateSocialMedia");
export const clear = createAction("signup/clear");
