import { createReducer } from "@reduxjs/toolkit";
import { ProfileExport } from "../../models/exports";
import {
  removeFromSocialMediaLinkList,
  updateSocialMediaLinkList,
} from "../../utils/utils";
import {
  removeEditSocialMedia,
  updateEditName,
  updateEditProfile,
  updateEditSocialMedia,
  updateEditUsername,
} from "./actions";

export const initialState: ProfileExport = {
  userID: null,
  address: null,
  name: null,
  email: null,
  username: null,
  socialMedia: [],
  fundsCurated: [],
  fundsParticpating: [],
  createdAt: null,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(updateEditProfile, (state, action) => {
      return action.payload;
    })
    .addCase(updateEditName, (state, action) => {
      state.name = action.payload;
    })
    .addCase(updateEditUsername, (state, action) => {
      state.username = action.payload;
    })
    .addCase(updateEditSocialMedia, (state, action) => {
      state.socialMedia = updateSocialMediaLinkList(
        state.socialMedia,
        action.payload
      );
    })
    .addCase(removeEditSocialMedia, (state, action) => {
      state.socialMedia = removeFromSocialMediaLinkList(
        state.socialMedia,
        action.payload
      );
    });
});
