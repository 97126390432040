import { createReducer } from "@reduxjs/toolkit";
import { SocialMediaLink } from "../../models/social_media_link";
import {
  backwardStep,
  clear,
  forwardStep,
  setStep,
  updateBanner,
  updateInfo,
  updateProfilePic,
  updateSocialMedia,
} from "./action";

//In order
export enum SignupStep {
  LOADING,
  CREATE_AUTH_INFO,
  CREATE_PROFILE_INFO,
  CREATE_PROFILE_IMG,
  CREATE_PROFILE_SOCIAL,
}

export interface SignupState {
  step: SignupStep;
  name: string;
  username: string;
  account: string;
  socialMediaLinks: {
    twitter: string;
    "nifty gateway": string;
    youtube: string;
    instagram: string;
    website: string;
  };
  profilePic: string | undefined;
  banner: string | undefined;
}

const initialState: SignupState = {
  step: SignupStep.LOADING,
  name: "",
  username: "",
  account: "",
  socialMediaLinks: {
    twitter: "",
    "nifty gateway": "",
    youtube: "",
    instagram: "",
    website: "",
  },
  profilePic: undefined,
  banner: undefined,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(updateInfo, (state, action) => {
      state.name = action.payload.name;
      state.username = action.payload.username;
      state.account = action.payload.account;
    })
    .addCase(updateProfilePic, (state, action) => {
      state.profilePic = action.payload;
    })
    .addCase(updateBanner, (state, action) => {
      state.banner = action.payload;
    })
    .addCase(updateSocialMedia, (state, action) => {
      state.socialMediaLinks[action.payload.type] = action.payload.value;
    })
    .addCase(forwardStep, (state) => {
      state.step = state.step + 1;
    })
    .addCase(backwardStep, (state) => {
      state.step = state.step - 1;
    })
    .addCase(setStep, (state, action) => {
      state.step = action.payload;
    })
    .addCase(clear, (state) => {
      return initialState;
    });
});
