// gatsby-browser.js
//We will wrap our application with the Web3JS Provider
import React from "react";
import { Provider } from "react-redux";
import TransactionUpdater from "./src/state/transactions/updater";
import Popups from "./src/components/popups";

//firebase
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";
import firebase from "gatsby-plugin-firebase";

// bugsnag
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import store from "./src/state";

import { ThemeProvider, createTheme, makeStyles } from "@mui/material/styles";

import "@fontsource/open-sans";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/800.css";
import "@fontsource/poppins";
import "@fontsource/poppins/700.css";
import "@fontsource/roboto-condensed";
import "@fontsource/roboto-condensed/700.css";
import "@fontsource/work-sans";
import "@fontsource/work-sans/700.css";
import "@fontsource/work-sans/600.css";

import { ChainId, DAppProvider } from "@usedapp/core";
import { CHAIN_ID } from "./src/constants";

Bugsnag.start({
  apiKey: process.env.BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.BUGSNAG_RELEASE_STAGE,
  enabledReleaseStages: ["staging", "production"],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const config = {
  readOnlyChainId: CHAIN_ID === 1 ? ChainId.Mainnet : ChainId.Rinkeby,
  readOnlyUrls: {
    [ChainId.Mainnet]: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
    [ChainId.Rinkeby]: `https://rinkeby.infura.io/v3/${process.env.INFURA_KEY}`,
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(255, 79, 99, 1)",
    },
  },
});

export const onClientEntry = () => {
  window.onload = () => {
    // source: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    // This resizes the window for mobile view ports since on mobile nav bars/address bars will appear/disappear
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const resizeMobile = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", resizeMobile);
  };
};

export const wrapRootElement = ({ element }) => {
  //Initialize it here to get page_view and other session info
  firebase.analytics();
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <DAppProvider config={config}>
          <Provider store={store}>
            <>
              <TransactionUpdater />
              {element}
              <Popups />
            </>
          </Provider>
        </DAppProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};
