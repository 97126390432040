// Inspired by https://github.com/Uniswap/interface/blob/781e774ce73eb613e78413365553d328264f3d44/src/state/application/reducer.ts
import { createSlice, nanoid } from "@reduxjs/toolkit";
import { DEFAULT_TXN_DISMISS_MS } from "../../constants";

import { SupportedChainId } from "../../constants/chains";

export type PopupContent = {
  txn: {
    hash: string;
    status?: boolean;
  };
};

export enum ApplicationModal {
  WALLET,
  SETTINGS,
  SELF_CLAIM,
  ADDRESS_CLAIM,
  CLAIM_POPUP,
  MENU,
  DELEGATE,
  VOTE,
  POOL_OVERVIEW_OPTIONS,
  NETWORK_SELECTOR,
  PRIVACY_POLICY,
}

type PopupList = Array<{
  key: string;
  show: boolean;
  content: PopupContent;
  removeAfterMs: number | null;
}>;

export interface ApplicationState {
  readonly popupList: PopupList;
}

const initialState: ApplicationState = {
  popupList: [],
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    addPopup(
      state,
      { payload: { content, key, removeAfterMs = DEFAULT_TXN_DISMISS_MS } }
    ) {
      state.popupList = (
        key
          ? state.popupList.filter((popup) => popup.key !== key)
          : state.popupList
      ).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs,
        },
      ]);
    },
    removePopup(state, { payload: { key } }) {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false;
        }
      });
    },
  },
});

export const { addPopup, removePopup } = applicationSlice.actions;
export default applicationSlice.reducer;
