import { Contract } from "@ethersproject/contracts";
import { getAddress } from "@ethersproject/address";
import { AddressZero } from "@ethersproject/constants";
import { ethers } from "ethers";
import { JsonRpcSigner, Web3Provider } from "@ethersproject/providers";
import { SocialMediaLink } from "../models/exports";
import { idText } from "typescript";
import { ENS_BASE } from "../constants";

// returns the checksummed address if the address is valid, otherwise returns false
export const isAddress = (value: any): boolean | string => {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
};

//from: https://emailregex.com/
export const isEmailValid = (email): boolean => {
  if (email.length === 0) {
    return false;
  } else {
    const isValid =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );
    return isValid;
  }
};

//From: https://www.regextester.com/104028
export const isPasswordValid = (password): boolean => {
  const isValid =
    /^(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}$/.test(
      password
    );
  // console.log(`password: ${isValid}`)
  return isValid;
};

// account is not optional
//Creates Signer object for a Contract object
export const getSigner = (
  library: Web3Provider,
  account: string
): JsonRpcSigner => {
  return library.getSigner(account).connectUnchecked();
};

// account is optional
//Creates Provider/Signer object for a Contract object
export const getProviderOrSigner = (
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner => {
  return account ? getSigner(library, account) : library;
};

// account is optional
//Creates Contract object
export const getContract = (
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract => {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account));
};

//Currently for twitter, nifty gateway, youtube, instagram, website
export const getSocialMediaType = (
  socialMediaLink: SocialMediaLink[],
  type: string
): SocialMediaLink | undefined => {
  return socialMediaLink.find((s) => s.type === type);
};

//Parses social media fields into a SocialMediaLink
export const parseSocialMediaLink = (
  type: string,
  id: string
): SocialMediaLink => {
  let url;
  switch (type) {
    case "twitter":
      url = `https://twitter.com/${id}`;
      break;
    case "nifty gateway":
      url = `https://niftygateway.com/profile/${id}`;
      break;
    case "youtube":
      url = `https://www.youtube.com/user/${id}`;
      break;
    case "instagram":
      url = `https://instagram.com/${id}`;
      break;
    case "website":
      url = id;
      break;
  }

  const socialMedia: SocialMediaLink = {
    type: type,
    url: url,
  };

  return socialMedia;
};

//This will return the social media username given a SocialMediaLink
export const getSocialMediaUsername = (
  type: "twitter" | "nifty gateway" | "youtube" | "instagram" | "website",
  socialMediaList: SocialMediaLink[]
): string | undefined => {
  const socialMediaObj = getSocialMediaType(socialMediaList, type);
  if (socialMediaObj) {
    if (type === "website") {
      //Return the whole website
      return socialMediaObj.url;
    } else {
      //Only extract the username which is the last part of the url
      return socialMediaObj.url.split("/").slice(-1)[0];
    }
  } else {
    return undefined;
  }
};

export const removeFromSocialMediaLinkList = (
  oldSocialMediaList: SocialMediaLink[],
  socialMediaType: string
) => {
  let newList = [...oldSocialMediaList];

  //Remove old social medias of the same type
  newList = newList.filter((i) => {
    return i.type !== socialMediaType;
  });

  return newList;
};

//Given a list of SocialMediaLink objects, remove old instances of the same type and add the new one
export const updateSocialMediaLinkList = (
  oldSocialMediaList: SocialMediaLink[],
  addSocial: SocialMediaLink
) => {
  let newList = removeFromSocialMediaLinkList(
    oldSocialMediaList,
    addSocial.type
  );

  newList.push(addSocial);

  return newList;
};

export const getSnapshotUrl = (albumName: string) => {
  return `https://snapshot.org/#/${albumName}.${ENS_BASE}`;
};

//Given an image link - determines if the link is an a video or not and returns a boolean value
export const linkIsVideo = (link: string) => {
  const videos = ["mp4", "flv", "m4a", "3gp", "mkv"];

  if (!link) {
    return false;
  }
  try {
    const splitURL = link.split(".");
    if (splitURL.length < 2) {
      return false;
    }
    // Some assetURLs have multiple periods in the URL so scanning through to find a video extension
    for (let index = 1; index < splitURL.length; index++) {
      const extension = splitURL[index];
      if (videos.includes(extension)) {
        return true;
      }
    }

    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// notification link router
export const buildNotificationLink = (messageType, messageDestination) => {
  if (
    [
      "album_published",
      "token_sale_started",
      "token_sale_ended",
      "add_nft_proposed",
      "add_nft_accepted",
      "add_nft_executed",
      "add_nft_declined",
    ].includes(messageType)
  ) {
    return `/album/${messageDestination.album}`;
  } else if (messageType === "welcome") {
    return `/account/settings`;
  } else if (["album_created"].includes(messageType)) {
    return `/${messageDestination.page}`;
  } else if (
    ["other_proposal_created", "proposal_submitted_to_dao"].includes(
      messageType
    )
  ) {
    return messageDestination.snapshot;
  } else {
    return "/explore";
  }
};

// returns a whole number, fixed(2) if decimal greater than 1, else < 0 fixed(3)
export const wholeOrDecimal = (number, lessThanZero = 3) => {
  let parsedNum = parseFloat(number);
  if (!parsedNum) {
    return 0;
  } else if (parsedNum === 0) {
    return parseFloat(parsedNum.toFixed(0));
  } else if (parsedNum < 1 && parsedNum > 0) {
    if (parsedNum < 0.00000000001) {
      return parsedNum.toFixed(12);
    } else if (parsedNum < 0.0000000001) {
      return parsedNum.toFixed(11);
    } else if (parsedNum < 0.000000001) {
      return parsedNum.toFixed(10);
    } else if (parsedNum < 0.00000001) {
      return parsedNum.toFixed(9);
    } else if (parsedNum < 0.0000001) {
      return parsedNum.toFixed(8);
    } else if (parsedNum < 0.000001) {
      return parsedNum.toFixed(7);
    } else if (parsedNum < 0.00001) {
      return parsedNum.toFixed(6);
    } else if (parsedNum < 0.0001) {
      return parsedNum.toFixed(5);
    } else if (parsedNum < 0.001) {
      return parsedNum.toFixed(4);
    } else if (parsedNum < 0.01) {
      return parsedNum.toFixed(3);
    } else {
      return parsedNum.toFixed(2);
    }
  } else if (parsedNum - Math.floor(parsedNum) === 0) {
    return parsedNum;
  } else {
    return parseFloat(parsedNum.toFixed(2));
  }
};

// abbreviates numbers by thousands, millions, billions, trillions
export const abbreviateNumber = (num) => {
  if (num >= 1000000000000) {
    return (num / 1000000000000).toFixed(1).replace(/\.0$/, "") + "T";
  }
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
};

export const shortenString = (string: string, limit: number) => {
  if (string && string.length > limit) {
    return `${string.slice(0, limit)}...`;
  } else {
    return string;
  }
};

export const shortenEns = (ens) => {
  if (ens && ens.length > 13) {
    return `${ens.slice(0, 6)}...${ens.slice(-4)}`;
  } else {
    return ens;
  }
};

export const sliceAddress = (address) => {
  return `${address.slice(0, 6)}...${address.slice(-5)}`;
};

export const snapshotHyperlink = (text: string, hyperlink: string) => {
  return `[${text}](${hyperlink})`;
};
