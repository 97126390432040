import { createAction } from "@reduxjs/toolkit";
import { NFTExport } from "../../models/exports";
import { CreateAlbumDetails, CreateAlbumStep } from "./reducer";

export const setDetails = createAction<CreateAlbumDetails>(
  "createAlbum/setDetails"
);
export const addNft = createAction<NFTExport>("createAlbum/addNft");
export const removeNft = createAction<NFTExport>("createAlbum/removeNft");
export const clearNft = createAction<NFTExport>("createAlbum/clearNft");
export const forwardStep = createAction("createAlbum/forwardStep");
export const backwardStep = createAction("createAlbum/backwardStep");
export const setStep = createAction<CreateAlbumStep>("createAlbum/setStep");
export const resetAlbumSetup = createAction<CreateAlbumStep>(
  "createAlbum/resetAlbumSetup"
);
