import React from "react";
import { useActivePopups } from "../../state/application/hooks";
import PopupItem from "./popupItem";

const Popups = () => {
  const activePopups = useActivePopups();

  return (
    <div
      className={`${
        activePopups.length === 0
          ? "hidden"
          : "fixed top-0 right-0 p-32 grid grid-cols-1 gap-8 z-50"
      }`}
      style={{ width: "377px" }}
    >
      {activePopups.map((popup) => (
        <PopupItem
          removeAfterMs={popup.removeAfterMs}
          content={popup.content}
          popKey={popup.key}
        />
      ))}
    </div>
  );
};

export default Popups;
