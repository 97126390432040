// Inspired by https://github.com/Uniswap/interface/blob/781e774ce73eb613e78413365553d328264f3d44/src/state/transactions/actions.ts
import { createAction } from "@reduxjs/toolkit";

export interface SerializableTransactionReceipt {
  to: string;
  from: string;
  contractAddress: string;
  transactionIndex: number;
  blockHash: string;
  transactionHash: string;
  blockNumber: number;
  status?: number;
}

export enum TransactionType {
  BOUNTY_BOARD_CLAIM = 0,
  ALBUM_CREATION = 1,
}

export interface BaseTransactionInfo {
  type: TransactionType;
}

export type TransactionInfo = BaseTransactionInfo;

export const addTransaction = createAction<{
  chainId: number;
  hash: string;
  from: string;
  info: TransactionInfo;
}>("transactions/addTransaction");
export const clearAllTransactions = createAction<{ chainId: number }>(
  "transactions/clearAllTransactions"
);
export const finalizeTransaction = createAction<{
  chainId: number;
  hash: string;
  receipt: SerializableTransactionReceipt;
}>("transactions/finalizeTransaction");
export const checkedTransaction = createAction<{
  chainId: number;
  hash: string;
  blockNumber: number;
}>("transactions/checkedTransaction");
