import { createReducer } from "@reduxjs/toolkit";
import { StandardProposal } from "../../models/standardProposals/standard";
import { ProposalType, PROPOSAL_TYPE } from "../../constants/proposals";
import {
  setAlbumDetails,
  setProposalType,
  updateDescription,
  updateProposalEnd,
  updateProposal,
  updateProposalStart,
  updateTitle,
  updateProposalAddtlInfo,
  clearProposal,
} from "./actions";
import { BountyProposal } from "../../models/standardProposals/bounty";
import { FullAlbumDetails } from "../../models/exports";
import { AddNftProposal } from "../../models/standardProposals/addNft";
import { ListNFTProposal } from "../../models/standardProposals/list/listNFTProposal";
import { TransferNFTProposal } from "../../models/standardProposals/transferNFT";
import { TransferTokensProposal } from "../../models/standardProposals/transferTokens";

export interface ProposalInfo {
  type: ProposalType;
  album: FullAlbumDetails;
  proposal: StandardProposal;
}

export const initialState: ProposalInfo = {
  type: undefined,
  album: undefined,
  proposal: undefined,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setProposalType, (state, action) => {
      if (action.payload) {
        state.type = action.payload;

        //We should initialize a blank object for the proposal
        switch (action.payload.type) {
          case PROPOSAL_TYPE.ADD_NFT:
            state.proposal = AddNftProposal.initialize();
            break;
          case PROPOSAL_TYPE.LIST_NFT:
            state.proposal = ListNFTProposal.initialize();
            break;
          case PROPOSAL_TYPE.BUY_NFT:
            break;
          case PROPOSAL_TYPE.DISTRIBUTE:
            break;
          case PROPOSAL_TYPE.BOUNTY:
            state.proposal = BountyProposal.initialBounty();
            break;
          case PROPOSAL_TYPE.BUY_OUT:
            break;
          case PROPOSAL_TYPE.TRANSFER_TOKENS:
            state.proposal = TransferTokensProposal.initialize();
            break;
          case PROPOSAL_TYPE.TRANSFER_ASSETS:
            break;
          case PROPOSAL_TYPE.TRANSFER_NFT:
            state.proposal = TransferNFTProposal.initialize();
            break;
          case PROPOSAL_TYPE.MANAGE_GUARDIANS:
            break;
        }
      }
    })
    .addCase(setAlbumDetails, (state, action) => {
      if (action.payload) {
        state.album = action.payload;
      }
    })
    .addCase(updateProposal, (state, action) => {
      state.proposal = action.payload;
    })
    .addCase(updateTitle, (state, action) => {
      state.proposal.title = action.payload;
    })
    .addCase(updateDescription, (state, action) => {
      state.proposal.description = action.payload;
    })
    .addCase(updateProposalStart, (state, action) => {
      state.proposal.start = action.payload;
    })
    .addCase(updateProposalEnd, (state, action) => {
      state.proposal.end = action.payload;
    })
    .addCase(updateProposalAddtlInfo, (state, action) => {
      state.proposal.addtionalInfo = action.payload;
    })
    .addCase(clearProposal, (state, action) => {
      return initialState;
    })
);
