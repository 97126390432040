export enum PROPOSAL_TYPE {
  ADD_NFT,
  LIST_NFT,
  BOUNTY,
  LIST_MULTIPLE_NFT,
  TRANSFER_TOKENS,
  TRANSFER_NFT,
  DISTRIBUTE,
  DISSOLVE,
  MANAGE_GUARDIANS,
}

export interface ProposalType {
  type: PROPOSAL_TYPE;
  title: string;
  description: string;
  path: string;
  disabled?: boolean;
}

export enum PROPOSAL_CATEGORY_TYPE {
  NFT_MANAGEMENT = "NFT Management",
  TRANSFER = "Transfer Assets",
  ALBUM_MANAGEMENT = "Album Management",
}

export interface PROPOSAL_CATEGORY {
  type: PROPOSAL_CATEGORY_TYPE;
  proposals: ProposalType[];
}

export const PROPOSALS: PROPOSAL_CATEGORY[] = [
  {
    type: PROPOSAL_CATEGORY_TYPE.NFT_MANAGEMENT,
    proposals: [
      {
        type: PROPOSAL_TYPE.ADD_NFT,
        title: "Add NFT",
        description: "Offer an NFT in exchange for a number of $ALBUM token",
        path: "addnft",
      },
      {
        type: PROPOSAL_TYPE.LIST_NFT,
        title: "List NFT",
        description: "List an NFT for sale on OpenSea",
        path: "list",
      },
      {
        type: PROPOSAL_TYPE.BOUNTY,
        title: "Bounty Board",
        description:
          "Create a bounty for qualified NFTs to receive an amount of $ALBUM",
        path: "bounty",
      },
      {
        type: PROPOSAL_TYPE.LIST_MULTIPLE_NFT,
        title: "List multiple NFTs",
        description: "List a bundle of NFTs for sale",
        path: "bounty",
        disabled: true,
      },
    ],
  },
  {
    type: PROPOSAL_CATEGORY_TYPE.TRANSFER,
    proposals: [
      {
        type: PROPOSAL_TYPE.TRANSFER_TOKENS,
        title: "Transfer ETH or Tokens",
        description: "Transfer ETH or Tokens to any public address",
        path: "transferTokens",
      },
      {
        type: PROPOSAL_TYPE.TRANSFER_NFT,
        title: "Transfer NFTs",
        description: "Transfer any NFT in the Album to a public address",
        path: "transferNFT",
      },
      {
        type: PROPOSAL_TYPE.DISTRIBUTE,
        title: "Evenly distribute tokens",
        description: "Send an ETH or ERC20 claim proportionally to holders",
        path: "distribute",
        disabled: true,
      },
    ],
  },
  {
    type: PROPOSAL_CATEGORY_TYPE.ALBUM_MANAGEMENT,
    proposals: [
      {
        type: PROPOSAL_TYPE.DISSOLVE,
        title: "Distribute and Dissolve",
        description: "Send an ETH or ERC20 claim in exchange for $ALBUM",
        path: "dissolve",
        disabled: true,
      },
      {
        type: PROPOSAL_TYPE.MANAGE_GUARDIANS,
        title: "Manage Guardians",
        description: "Add or remove addresses as Guardians of the Album",
        path: "manageGuardians",
        disabled: true,
      },
    ],
  },
];

export const getProposalMetadata = (type: PROPOSAL_TYPE) => {
  let matchedProposal;

  PROPOSALS.find((category) => {
    return !!category.proposals.find((proposal) => {
      if (proposal.type === type) {
        matchedProposal = proposal;
        return true;
      } else {
        return false;
      }
    });
  });

  return matchedProposal;
};
