import { StandardProposal } from "./standard";
import { NFTExport } from "models/exports";

export interface TransferNFTProposal extends StandardProposal {
  recipient: Recipient;
  selectedNfts: NFTExport[];
}

export class TransferNFTProposal implements TransferNFTProposal {
  /**
   * Creates a initial blank New Proposal object
   * @returns
   */
  static initialize(): TransferNFTProposal {
    return {
      title: "", // we must initialize the StandardProposal fields too
      description: "",
      addtionalInfo: "",
      start: undefined,
      end: undefined,
      recipient: { ens: "", address: "" },
      selectedNfts: [],
    };
  }
}

export interface Recipient {
  ens: string;
  address: string;
}
