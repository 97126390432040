import { StandardProposal } from "./standard";

/**
 * This defines a bounty board proposal
 */
export class BountyProposal implements BountyProposal {
  /**
   * Creates a initial blank bounty board object
   * @returns
   */
  static initialBounty(): BountyProposal {
    return {
      title: "",
      description: "",
      addtionalInfo: "",
      start: undefined,
      end: undefined,
      validator: "",
      nftBeneficiary: "",
      default: {
        tokensPerTribute: undefined,
        maxFills: undefined,
        expirationTime: undefined,
        nftContract: undefined,
      },
      overrides: {},
    };
  }
}

export interface BountyProposal extends StandardProposal {
  validator: string; // A separate validator contract address for this order
  nftBeneficiary: string; // Address where the nfts will go, eg: album
  default: BountyOrder;
  overrides: BountyOverridesMap;
}

export interface BountyOrder {
  tokensPerTribute: number; //Exchange Rate
  maxFills: number; //Redemption Limit
  expirationTime: number;
  nftContract?: string;
  tokenID?: number;
}

export interface BountyOverride {
  isOverride: boolean; //If false should take default params set in proposal
  default: BountyOrder;
  orders: BountyOrder[];
  collectionName?: string;
  collectionAddress?: string;
  collectionImageURL?: string;
}

// Make object literal so it plays nice with redux
export interface BountyOverridesMap {
  [contract: string]: BountyOverride;
}
