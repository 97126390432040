import { createReducer } from "@reduxjs/toolkit";
import { BountyOrderExport, BountyValidatorExport } from "../../models/exports";
import { Order, OrderSelection } from "models/bounty";
import {
  initalizeCollectionStore,
  nftSelected,
  nftDeselected,
  clearSelectedNFTs,
  saveOrdersForCollections,
  initializeAlbumDetails,
  resetBounties,
  loadCollections,
  setAllContractsApproved,
  saveValidatorsForOrders,
} from "./actions";
import { Collection } from "../../models/collection";

interface BountyOrderState {
  collections:
    | { [collectionAddress: string]: { [orderId: string]: BountyOrderExport } }
    | undefined;
  selected: OrderSelection[];
  albumName: string;
  albumToken: string;
  collectionMetadata: { [collectionAddress: string]: Collection };
  allContractsApproved: boolean;
  validators: { [validatorId: string]: BountyValidatorExport };
}

const initialState: BountyOrderState = {
  collections: {},
  selected: [],
  albumName: "",
  albumToken: "",
  collectionMetadata: {},
  allContractsApproved: false,
  validators: {},
};

export default createReducer(initialState, (builder) => {
  builder
    // Setup initial state for all collections
    .addCase(initalizeCollectionStore, (state, action) => {
      if (action.payload) {
        Object.entries(action.payload).map(
          ([address, _]: [string, Collection]) => {
            state.collections[address] = {};
          }
        );
      }
    })
    // Setup album metadata we need
    .addCase(initializeAlbumDetails, (state, action) => {
      if (action.payload) {
        state.albumName = action.payload.albumName;
        state.albumToken = action.payload.albumToken;
      }
    })
    // Initalize and load order data for a specific collection
    .addCase(saveOrdersForCollections, (state, action) => {
      if (action.payload) {
        Object.entries(action.payload).map(
          ([collectionAddress, orders]: [string, any[]]) => {
            orders.map((order) => {
              state.collections[collectionAddress][order.id] = order;
            });
          }
        );
      }
    })
    .addCase(saveValidatorsForOrders, (state, action) => {
      if (action.payload) {
        Object.entries(action.payload).map(
          ([validatorAddress, validator]: [string, BountyValidatorExport]) => {
            state.validators[validatorAddress] = validator;
          }
        );
      }
    })
    // Add tokenId to order's selected NFT set
    .addCase(nftSelected, (state, action) => {
      if (action.payload && !isTokenSelected(action.payload, state.selected)) {
        state.selected.push(action.payload);
      }
    })
    // Remove tokenId from Order's selected NFT set
    .addCase(nftDeselected, (state, action) => {
      if (action.payload) {
        state.selected = state.selected.filter((item) => {
          return !(
            item.type.contract === action.payload.type.contract &&
            item.orderId === action.payload.orderId &&
            item.id === action.payload.id
          );
        });
      }
    })
    // Remove all token ids that were selected for a collection
    .addCase(clearSelectedNFTs, (state, action) => {
      state.selected = [];
    })
    .addCase(resetBounties, (state, action) => {
      return initialState;
    })
    .addCase(loadCollections, (state, action) => {
      state.collectionMetadata = action.payload;
    })
    .addCase(setAllContractsApproved, (state, action) => {
      state.allContractsApproved = action.payload;
    });
  /*
    NOT YET IMPLEMENTED
    .addCase(selectAll, (state, action) => {
        if (action.payload) {
            const allTokenIDsForCollection = action.payload.userNfts;
            const collectionAddress = action.payload.collectionAddress;
            const collection = state.collections[collectionAddress]
            Object.keys(collection).map((orderId) => {
                collection[orderId].selectedNFTs.tokenIds = allTokenIDsForCollection
            })
        }
    })*/
});

/**
 * Checks if an order/nft is already in a list
 * @param nft the order/nft to check
 * @param selected the list of orders/nfts
 * @returns true if in the list
 */
const isTokenSelected = (
  nft: OrderSelection,
  selected: OrderSelection[]
): boolean => {
  return selected.some((n) => {
    return (
      n.type.contract === nft.type.contract &&
      n.id === nft.id &&
      n.orderId === nft.orderId
    );
  });
};
