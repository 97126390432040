// Inspired by https://github.com/Uniswap/interface/blob/781e774ce73eb613e78413365553d328264f3d44/src/state/transactions/updater.tsx
import { useEthers } from "@usedapp/core";
import LibUpdater from "../../lib/hooks/transactions/updater";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAddPopup } from "../application/hooks";
import { checkedTransaction, finalizeTransaction } from "./actions";
import { AppState } from "state";
import React from "react";

export default function Updater() {
  const { chainId } = useEthers();
  const addPopup = useAddPopup();

  const dispatch = useDispatch();
  const onCheck = useCallback(
    ({ chainId, hash, blockNumber }) =>
      dispatch(checkedTransaction({ chainId, hash, blockNumber })),
    [dispatch]
  );
  const onReceipt = useCallback(
    ({ chainId, hash, receipt }) => {
      dispatch(
        finalizeTransaction({
          chainId,
          hash,
          receipt: {
            blockHash: receipt.blockHash,
            blockNumber: receipt.blockNumber,
            contractAddress: receipt.contractAddress,
            from: receipt.from,
            status: receipt.status,
            to: receipt.to,
            transactionHash: receipt.transactionHash,
            transactionIndex: receipt.transactionIndex,
          },
        })
      );
      addPopup(
        {
          txn: {
            hash: hash,
            status: receipt?.status,
          },
        },
        hash
      );
    },
    [addPopup, dispatch]
  );

  const state = useSelector<AppState, AppState["transactions"]>(
    (state) => state.transactions
  );
  const pendingTransactions = useMemo(
    () => (chainId ? state[chainId] ?? {} : {}),
    [chainId, state]
  );
  return (
    <LibUpdater
      pendingTransactions={pendingTransactions}
      onCheck={onCheck}
      onReceipt={onReceipt}
    />
  );
}
