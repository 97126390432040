import { Transition } from "@headlessui/react";
import React, { FC, useCallback, useEffect } from "react";
import { useRemovePopup } from "../../state/application/hooks";
import { PopupContent } from "../../state/application/reducer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { ETHERSCAN_TXN } from "../../constants";

interface PopupItemProps {
  removeAfterMs: number;
  content: PopupContent;
  popKey: string;
}

const PopupItem: FC<PopupItemProps> = ({ removeAfterMs, content, popKey }) => {
  const removePopup = useRemovePopup();
  const removeThisPopup = useCallback(
    () => removePopup(popKey),
    [popKey, removePopup]
  );

  useEffect(() => {
    if (removeAfterMs === null) return undefined;

    const timeout = setTimeout(() => {
      removeThisPopup();
    }, removeAfterMs);

    return () => {
      clearTimeout(timeout);
    };
  }, [removeAfterMs, removeThisPopup]);

  return (
    <Transition
      appear={true}
      show={true}
      enter="transition duration-300"
      enterFrom="transform translate-x-full"
      enterTo="transform translate-x-0"
      leave="transition duration-300"
      leaveFrom="transform translate-x-0"
      leaveTo="transform translate-x-full"
    >
      <div className="flex flex-col space-y-24 px-16 pt-16 pb-20 bg-dark-divider text-white">
        <div className="flex flex-row space-x-10 justify-start items-center">
          {content?.txn?.status === undefined ? (
            <AutorenewIcon sx={{ fontSize: "20px" }} />
          ) : content?.txn?.status ? (
            <CheckCircleIcon sx={{ fontSize: "20px" }} />
          ) : (
            <CancelIcon className="text-dark-red" sx={{ fontSize: "20px" }} />
          )}
          <h3>
            Transaction{" "}
            {content?.txn?.status === undefined
              ? "pending"
              : content?.txn?.status
              ? "complete"
              : "failed"}
          </h3>
        </div>
        <a
          href={`${ETHERSCAN_TXN}${content.txn.hash}`}
          target="_blank"
          className="w-1/2 text-dark-red btn-second"
        >
          View Transaction
        </a>
      </div>
    </Transition>
  );
};

export default PopupItem;
