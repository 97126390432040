// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-index-jsx": () => import("./../../../src/pages/account/index.jsx" /* webpackChunkName: "component---src-pages-account-index-jsx" */),
  "component---src-pages-album-index-jsx": () => import("./../../../src/pages/album/index.jsx" /* webpackChunkName: "component---src-pages-album-index-jsx" */),
  "component---src-pages-album-trashdao-index-tsx": () => import("./../../../src/pages/album/trashdao/index.tsx" /* webpackChunkName: "component---src-pages-album-trashdao-index-tsx" */),
  "component---src-pages-explore-tsx": () => import("./../../../src/pages/explore.tsx" /* webpackChunkName: "component---src-pages-explore-tsx" */),
  "component---src-pages-farm-tsx": () => import("./../../../src/pages/farm.tsx" /* webpackChunkName: "component---src-pages-farm-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-notifications-tsx": () => import("./../../../src/pages/notifications.tsx" /* webpackChunkName: "component---src-pages-notifications-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-staking-tsx": () => import("./../../../src/pages/staking.tsx" /* webpackChunkName: "component---src-pages-staking-tsx" */)
}

