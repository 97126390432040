import { StandardProposal } from "./standard";

export interface TransferTokensProposal extends StandardProposal {
  totalAmount: number; // Amount of ETH/ERC20 tokens to transfer
  selectedAsset: SelectedAsset;
  recipients: Recipient[];
}

export class TransferTokensProposal implements TransferTokensProposal {
  /**
   * Creates a initial blank New Proposal object
   * @returns
   */
  static initialize(): TransferTokensProposal {
    return {
      title: "", // we must initialize the StandardProposal fields too
      description: "",
      addtionalInfo: "",
      start: undefined,
      end: undefined,
      totalAmount: 0,
      selectedAsset: { contractAddress: "", name: "", balance: 0 },
      recipients: [],
    };
  }
}

export interface Recipient {
  ens: string;
  address: string;
  amount: number;
}

export interface SelectedAsset {
  contractAddress: string;
  name: string;
  balance: number;
}
