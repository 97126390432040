import { createAction } from "@reduxjs/toolkit";
import { OrderSelection } from "models/bounty";

export const clearSelectedNFTs = createAction("bounty/clearSelectedNFTs");
export const nftSelected = createAction<OrderSelection>("bounty/nftSelected");
export const nftDeselected = createAction<OrderSelection>(
  "bounty/nftDeselected"
);
export const initalizeCollectionStore = createAction<any>(
  "bounty/initalizeCollectionStore"
);
export const initializeAlbumDetails = createAction<{
  albumName: string;
  albumToken: string;
}>("bounty/initializeAlbumDetails");
export const saveOrdersForCollections = createAction<any>(
  "bounty/saveOrdersForCollections"
);
export const saveValidatorsForOrders = createAction<any>(
  "bounty/saveValidatorsForOrders"
);
export const selectAll = createAction<any>("bounty/selectAll");
export const resetBounties = createAction("bounty/resetBounties");
export const loadCollections = createAction<any>("bounty/loadCollections");
export const setAllContractsApproved = createAction<boolean>(
  "bounty/setAllContractsApproved"
);
